@import "/styles/mixins/mixins.scss";
$transformAnimationTime: 600ms; //300
$opacityAnimationTime: 600ms; //600

.section {
  position: relative;
  * {
    z-index: 1;
  }
  .content {
    will-change: opacity, visibility;
    width: 100%;
    box-sizing: border-box;
  }
}

.Section {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-position: 50% 50%;
  transition: background-image 0 ease;
  background-size: cover;

  --webkit-backface-visibility: hidden;
  --moz-backface-visibility: hidden;
  --ms-backface-visibility: hidden;

  .container {
    @include container;

    max-width: var(--section-container);
    padding-left: var(--section-container-pd);
    padding-right: var(--section-container-pd);
  }
}

.Container {
  display: flex;
}

.invert-true .Container {
  color: white;
}
.invert-true .Container * {
  color: white;
}
