@import "../../../../styles/responsive";

.footer {
  background-color: #080808;
  padding: 100px 20px 40px;
  position: relative;
  a {
    color: #fff;
    font-weight: normal;
    text-decoration: none;
    text-transform: uppercase;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    letter-spacing: 0.12em;
  }

  .footerInner {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    .content {
      margin-bottom: 60px;
      color: #fff;

      p {
        color: #fff;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 190%;
        letter-spacing: 0.03em;
        text-transform: capitalize;
        margin: 0;
        a {
          font-size: 18px;
        }
      }
    }

    .links {
      display: flex;
      flex-wrap: wrap;
      gap: 38px;
      justify-content: space-between;
      align-items: center;

      .left {
        display: flex;
        justify-content: flex-start;
        gap: 52px;
        align-items: center;
        min-width: fit-content;
      }
      .right {
        display: flex;
        justify-content: flex-end;
        gap: 52px;
        align-items: center;
        min-width: fit-content;
      }
    }

    .bottom {
      margin-top: 80px;
      display: flex;
      flex-wrap: wrap;
      gap: 38px;
      justify-content: space-between;
      align-items: center;
      color: #ffffff85;
    }
    .btt {
      position: absolute;
      z-index: 10;
      right: 0;
      top: 0;
      font-size: 42px;
      width: 42px;
      height: 42px;
      cursor: pointer;
      span {
        display: flex;
        width: 42px;
        height: 42px;
      }

      svg {
        width: 42px;
        height: 42px;
        path {
          fill: #c99b5f;
        }
      }
    }
  }

  .rights {
    display: none;
  }
}

@media only screen and (max-width: 500px) {
  .footer {
    .footerInner {
      .btt {
        top: -60px;
      }
      .links {
        gap: 32px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        .left,
        .right {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          text-align: left;
          gap: 32px;
        }
      }

      .content {
        p,
        a,
        p > a {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
}
