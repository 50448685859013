.Flex {
  --grid-gap: 20px;
  --grid-item-width: 1;
  gap: var(--grid-gap);

  width: 100%;

  .FlexGrid {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    gap: var(--grid-gap);

    &.justify-left {
      justify-content: flex-start;
    }
    &.justify-center {
      justify-content: center;
    }
    &.justify-right {
      justify-content: flex-end;
    }
    &.justify-space-between {
      justify-content: space-between;
    }
  }
}

.Column {
  /*flex-grow: 1;*/
  display: flex;
  justify-content: stretch;

  & > * {
    width: fit-content;
  }

  &.align-top {
    align-items: flex-start;
  }
  &.align-center {
    align-items: center;
  }
  &.align-bottom {
    align-items: flex-end;
  }
}

@media only screen and (min-width: 1200px) {
  .FlexGrid {
    &.justify-large-left {
      justify-content: flex-start !important;
    }
    &.justify-large-center {
      justify-content: center !important;
    }
    &.justify-large-right {
      justify-content: flex-end !important;
    }
    &.justify-large-space-between {
      justify-content: space-between !important;
    }
  }
  .Column {
    &.align-large-top {
      align-items: flex-start !important;
    }
    &.align-large-center {
      align-items: center !important;
    }
    &.align-large-bottom {
      align-items: flex-end !important;
    }
  }
}
@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 1000px) {
  .FlexGrid {
    &.justify-small-left {
      justify-content: flex-start !important;
    }
    &.justify-small-center {
      justify-content: center !important;
    }
    &.justify-small-right {
      justify-content: flex-end !important;
    }
    &.justify-small-space-between {
      justify-content: space-between !important;
    }
  }

  .Column {
    &.align-small-top {
      align-items: flex-start !important;
    }
    &.align-small-center {
      align-items: center !important;
    }
    &.align-small-bottom {
      align-items: flex-end !important;
    }
  }
}

@media only screen and (max-width: 650px) {
  .FlexGrid {
    &.justify-extra-small-left {
      justify-content: flex-start !important;
    }
    &.justify-extra-small-center {
      justify-content: center !important;
    }
    &.justify-extra-small-right {
      justify-content: flex-end !important;
    }
    &.justify-extra-space-between {
      justify-content: space-between !important;
    }
  }

  .Column {
    &.align-extra-small-top {
      align-items: flex-start !important;
    }
    &.align-extra-small-center {
      align-items: center !important;
    }
    &.align-extra-small-bottom {
      align-items: flex-end !important;
    }
  }
}
