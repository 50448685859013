.content {
  li,
  p,
  p a {
    font-size: var(--content-font-size);
    color: var(--content-text-color);
  }
}

@media only screen and (max-width: 750px) {
  .content {
    min-width: unset !important;
    width: unset !important;

    li,
    p,
    p a {
      font-size: var(--content-font-size-mobile);
    }

    p a {
      font-weight: 400;
    }
  }
}
