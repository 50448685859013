.Projects {
  overflow: visible;
  min-height: 500px;
  h2 {
    color: #fff;
  }
  .ProjectItems {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    position: relative;
    z-index: 1;
    & > div {
      width: calc(33% - 20px);
      min-width: 280px;
    }
  }

  .ProjectFilters {
    position: relative;
    z-index: 10;
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    margin-bottom: 32px;
    justify-content: space-between;
    align-items: flex-start;

    & > div {
      width: calc(33% - 32px);
      min-width: 280px;
      position: relative;
      overflow: visible;
    }
  }
}

.Filter {
  background-color: #1b1b1b;
  position: relative;
  color: #fff;
  border: 1px solid transparent;

  border-bottom: 1px solid #fff;
  overflow: visible;
  transition: all 0.2s ease-in-out;

  .Label {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 24px;
    padding: 18px 8px;
    text-transform: uppercase;
    cursor: pointer;
    border-bottom: 1px solid #fff;
    font-weight: 600;
    font-size: 12px;
    line-height: 120%;
    transition: all 0.2s ease-in-out;

    letter-spacing: 0.12em;
    text-transform: uppercase;
    .indicator {
      transition: all 0.2s ease-in-out;
      svg {
        rotate: 90deg;
        path {
          fill: #c99b5f;
        }
      }
    }

    &:hover {
      // background-color: #000;
    }
  }

  .Dropdown {
    display: none;
    flex-direction: column;
    width: 100%;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 10;
    background-color: #1b1b1b;
    transition: all 0.2s ease-in-out;
    // border: 1px solid #fff;

    .Option {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 24px;
      padding: 18px 8px;
      text-transform: uppercase;
      cursor: pointer;
      border-bottom: 1px solid #fff;
      transition: all 0.2s ease-in-out;

      font-weight: 600;
      font-size: 12px;
      line-height: 120%;
      letter-spacing: 0.12em;
      text-transform: uppercase;
      &:hover {
        // background-color: #080808;
        text-decoration: underline;
      }

      .check {
        width: 24px;
        height: 24px;
        svg {
          scale: 0.5;
          translate: -25% -25%;
          path {
            fill: #c99b5f;
          }
        }
      }
    }
  }

  &[data-open="true"] {
    .Dropdown {
      display: flex;
    }
    .Label {
      .indicator {
        rotate: 180deg;
      }
    }
  }
}

.clearButton {
  margin-bottom: 52px;
  border: 1px solid #fff;
  background-color: transparent;
  span {
    width: 20px;
    height: 20px;
    svg {
      width: 20px;
      height: 20px;
      path {
        fill: #c99b5f;
      }
    }
  }

  &:hover {
    background-color: #000;
  }
}

.Project {
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 248px;
  box-sizing: border-box;
  border: 1px solid #101e2366;
  transition: all 0.2s ease-in-out;

  position: relative;
  overflow: hidden;

  .Background {
    z-index: 0;
    background-color: #101e2366;
    background-position: 50% 50%;
    background-size: cover;
    background-blend-mode: overlay;
    transition: all 0.2s ease-in-out;
    position: absolute;
    inset: 0;

    &[data-image="false"] {
      background-color: #1f2426;
    }
  }

  .Content {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: #fff;
    font-weight: 500;
    font-size: 16px;
    line-height: 190%;
  }

  &:hover {
    border: 1px solid #c99b5f;
    .Background {
      // background-color: transparent;
      scale: 1.2;
    }
  }
}

.bigClicker {
  position: absolute;
  inset: 0 0 0 0;
  cursor: pointer;
}

@media only screen and (max-width: 750px) {
  .Projects {
    .ProjectFilters {
      & > div {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .Projects {
    .ProjectFilters {
      .Filter {
        .Label {
          .indicator {
            scale: 0.6;
          }
        }
      }
      .Filter[data-open="true"] {
        position: fixed;
        z-index: 10000100000000000000;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #1b1b1b;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 40px;

        .Label {
          margin-top: auto;
          width: 100%;
          background-color: unset;
          .indicator {
            position: fixed;
            top: 12px;
            right: 25px;
            scale: 1;
          }
        }
        .Dropdown {
          position: relative;
          inset: unset;
          width: 100%;
        }
        .buttons {
          display: flex;
          margin-top: auto;
          gap: 24px;
          align-items: center;

          .applyButton {
            border: 1px solid #fff;
            background-color: #fff;
            color: #080808;
            padding-right: 24px;
          }

          .clearButton {
            border: none;
            margin-bottom: 0;
          }
        }
      }
    }
    .ProjectItems {
      & > div {
        width: 100%;
        .Project {
          height: 180px;
        }
      }
    }
  }
}
