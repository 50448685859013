.SwipeCards {
  display: flex;
  flex-direction: column;
  gap: 40px;

  .actions {
    display: flex;
    justify-content: center;
    gap: 90px;
    align-items: center;

    .left,
    .right {
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      svg {
        scale: 1.8;

        path {
          fill: #080808;
        }
      }

      &:hover {
        scale: 1.1;
      }

      &[data-disabled="true"] {
        pointer-events: none;

        svg {
          path {
            fill: grey;
          }
        }
      }
    }
  }

  .slides {
    display: flex;
    height: 400px;

    .slideWrapper {
      width: 100%;
      position: absolute;
      left: 50%;
      top: 50%;
      translate: -50% -50%;
      max-width: 730px;
      height: 288px;
      transition: all 0.2s ease-in-out;

      .slide {
        background: #ffffff;
        box-shadow: 0px 31px 46px rgba(28, 28, 112, 0.05);
        border-radius: 16px;
        box-sizing: border-box;
        padding: 32px;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;

        .message {
          font-family: "Libre Baskerville";
          font-style: normal;
          font-weight: 400;
          font-size: 24px;
          line-height: 150%;
          color: #080808;
          margin: 0;
          transition: all 0.2s ease-in-out;
        }

        .info {
          border-top: 1px solid #925cff;
          padding: 16px 0 0;
          display: flex;
          flex-direction: column;
          gap: 0;
          transition: all 0.2s ease-in-out;

          .author {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 700;
            font-size: 16px;
            line-height: 150%;
            color: #080808;
          }
          .from {
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            color: #080808;
          }
        }
      }

      &[data-index="0"] {
        translate: calc(-50% - 15%) calc(-50% - 32px);
        z-index: 10;
        transition: all 0.4s ease-in-out;
      }
      &[data-index="1"] {
        z-index: 5;
        transition: all 0.2s ease-in-out 0.4s;

        .message,
        .info {
          opacity: 0.6;
        }
      }
      &[data-index="2"] {
        translate: calc(-50% + 15%) calc(-50% + 32px);
        z-index: 2;
        transition: all 0.2s ease-in-out 0.4s;

        .message,
        .info {
          opacity: 0.4;
        }
      }
      &[data-index="3"] {
        opacity: 0;
        translate: calc(-50% + 15%) calc(-50% + 32px);
        z-index: 2;
        transition: all 0.2s ease-in-out 0.4s;

        .message,
        .info {
          opacity: 0.4;
        }
      }
      &[data-last="true"] {
        opacity: 0;
        translate: calc(-50% - 15%) calc(-50%);
        z-index: 10;
        transition: all 0.2s ease-in-out 0.4s;

        .message,
        .info {
          opacity: 0.4;
        }
      }
    }
    &[data-animate="none"] {
      .slideWrapper {
        &[data-index="0"] {
          transition: none;
        }

        &[data-index="1"] {
          transition: none;
        }

        &[data-index="2"] {
          transition: none;
        }
        &[data-index="3"] {
          transition: none;
        }
        &[data-last="true"] {
          transition: none;
        }
      }
    }

    &[data-animate="right"] {
      .slideWrapper {
        &[data-index="0"] {
          opacity: 0;
          translate: calc(-50% - 15%) calc(-50% + 32px);
          transition: all 0.4s ease-in-out;
        }

        &[data-index="1"] {
          translate: calc(-50% - 15%) calc(-50% - 32px);
          z-index: 10;
          transition: all 0.2s ease-in-out 0.4s;
          .message,
          .info {
            opacity: 1;
          }
        }

        &[data-index="2"] {
          translate: calc(-50%) calc(-50%);
          z-index: 5;
          transition: all 0.2s ease-in-out 0.4s;
          .message,
          .info {
            opacity: 0.6;
          }
        }
        &[data-index="3"] {
          opacity: 1;
          transition: all 0.2s ease-in-out 0.4s;
        }

        &[data-last="true"] {
          opacity: 0;
          translate: calc(-50% - 15%) calc(-50%);
          z-index: -1;
          transition: none;
          transition: all 0.2s ease-in-out 0.4s;

          .message,
          .info {
            opacity: 0.4;
          }
        }
      }
    }

    &[data-animate="left"] {
      .slideWrapper {
        &[data-last="true"] {
          opacity: 1;
          translate: calc(-50% - 15%) calc(-50% - 32px);
          z-index: 10;
          transition: all 0.4s ease-in-out 0.4s;

          .message,
          .info {
            opacity: 1;
          }
        }

        &[data-index="0"] {
          opacity: 1;
          z-index: 5;
          translate: calc(-50%) calc(-50%);
          transition: all 0.4s ease-in-out;

          .message,
          .info {
            opacity: 0.6;
          }
        }

        &[data-index="1"] {
          translate: calc(-50% + 15%) calc(-50% + 32px);
          z-index: 2;
          transition: all 0.4s ease-in-out;

          .message,
          .info {
            opacity: 0.4;
          }
        }

        &[data-index="2"] {
          opacity: 0;
          transition: all 0.4s ease-in-out;
          pointer-events: none;
        }
        &[data-index="3"] {
          opacity: 0;
          transition: none;
          pointer-events: none;
        }
      }
    }
  }

  &[data-debug="true"] {
    .slides {
      .slideWrapper {
        &[data-index="0"] {
          &:after {
            content: "0";
            position: absolute;
            background-color: red;
            inset: 0 0 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            z-index: 100;
          }
        }
        &[data-index="1"] {
          &:after {
            content: "1";
            position: absolute;
            background-color: green;
            inset: 0 0 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            z-index: 100;
          }
        }
        &[data-index="2"] {
          &:after {
            content: "2";
            position: absolute;
            background-color: yellow;
            inset: 0 0 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            z-index: 100;
          }
        }
        &[data-index="3"] {
          &:after {
            content: "3";
            position: absolute;
            background-color: blue;
            inset: 0 0 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            z-index: 100;
          }
        }

        &[data-last="true"] {
          &:after {
            content: "last";
            position: absolute;
            background-color: purple;
            inset: 0 0 0 0;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 30px;
            z-index: 100;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .SwipeCards {
    overflow: hidden;
    // flex-direction: row;
    // flex-wrap: wrap-reverse;
    // justify-content: center;
    margin-top: 20px;
    .actions {
      display: none;
    }

    .slides {
      gap: 8px;
      height: fit-content;
      transition: 0.4s ease-in-out;
      translate: calc(-95% - 8px + 20px) 0;
      padding: 0 10px;
      max-width: 100vw;
      padding-bottom: 50px;
      &[data-animate="right"] {
        translate: calc((-95% * 2) - 16px + 40px - 1px) 0;
      }
      &[data-animate="left"] {
        translate: 0 0;

        .slideWrapper {
          &[data-last="true"] {
            opacity: 1;

            translate: calc(-95% - 8px + 20px) 0;
            z-index: 10;
            transition: none;

            .message,
            .info {
              opacity: 1;
            }
          }
        }
      }

      &[data-animate="none"] {
        transition: none;
      }

      .slideWrapper {
        height: fit-content;

        position: relative !important;
        translate: unset !important;
        top: unset !important;
        left: unset !important;
        min-width: 95%;
      }
    }
  }
}
