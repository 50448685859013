.Columns {
  --grid-gap: 20px;
  --grid-item-width: 1;
  gap: var(--grid-gap);

  width: 100%;

  .ColumnsGrid {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    gap: var(--grid-gap);

    &.justify-left {
      justify-content: flex-start;
    }
    &.justify-center {
      justify-content: center;
    }
    &.justify-right {
      justify-content: flex-end;
    }
    &.justify-space-between {
      justify-content: space-between;
    }
  }
}

.Column {
  /*flex-grow: 1;*/
  display: flex;
  justify-content: stretch;

  & > * {
    width: 100%;
  }

  &.align-top {
    align-items: flex-start;
  }
  &.align-center {
    align-items: center;
  }
  &.align-bottom {
    align-items: flex-end;
  }
}

@media only screen and (min-width: 1200px) {
  .Columns-large-12 > .ColumnsGrid > .Column {
    width: calc(
      100% / 12 * var(--grid-item-width) - var(--grid-gap)
    ) !important;
  }
  .Columns-large-11 > .ColumnsGrid > .Column {
    width: calc(
      100% / 11 * var(--grid-item-width) - var(--grid-gap)
    ) !important;
  }
  .Columns-large-10 > .ColumnsGrid > .Column {
    width: calc(
      100% / 10 * var(--grid-item-width) - var(--grid-gap)
    ) !important;
  }
  .Columns-large-9 > .ColumnsGrid > .Column {
    width: calc(100% / 9 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-large-8 > .ColumnsGrid > .Column {
    width: calc(100% / 8 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-large-7 > .ColumnsGrid > .Column {
    width: calc(100% / 7 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-large-6 > .ColumnsGrid > .Column {
    width: calc(100% / 6 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-large-5 > .ColumnsGrid > .Column {
    width: calc(100% / 5 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-large-4 > .ColumnsGrid > .Column {
    width: calc(100% / 4 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-large-3 > .ColumnsGrid > .Column {
    width: calc(100% / 3 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-large-2 > .ColumnsGrid > .Column {
    width: calc(100% / 2 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-large-1 > .ColumnsGrid > .Column {
    width: 100% !important;
  }

  .ColumnsGrid {
    &.justify-large-left {
      justify-content: flex-start !important;
    }
    &.justify-large-center {
      justify-content: center !important;
    }
    &.justify-large-right {
      justify-content: flex-end !important;
    }
    &.justify-large-space-between {
      justify-content: space-between !important;
    }
  }
  .Column {
    &.align-large-top {
      align-items: flex-start !important;
    }
    &.align-large-center {
      align-items: center !important;
    }
    &.align-large-bottom {
      align-items: flex-end !important;
    }
  }
}
@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 1000px) {
  .Columns-small-12 > .ColumnsGrid > .Column {
    width: calc(
      100% / 12 * var(--grid-item-width) - var(--grid-gap)
    ) !important;
  }
  .Columns-small-11 > .ColumnsGrid > .Column {
    width: calc(
      100% / 11 * var(--grid-item-width) - var(--grid-gap)
    ) !important;
  }
  .Columns-small-10 > .ColumnsGrid > .Column {
    width: calc(
      100% / 10 * var(--grid-item-width) - var(--grid-gap)
    ) !important;
  }
  .Columns-small-9 > .ColumnsGrid > .Column {
    width: calc(100% / 9 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-small-8 > .ColumnsGrid > .Column {
    width: calc(100% / 8 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-small-7 > .ColumnsGrid > .Column {
    width: calc(100% / 7 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-small-6 > .ColumnsGrid > .Column {
    width: calc(100% / 6 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-small-5 > .ColumnsGrid > .Column {
    width: calc(100% / 5 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-small-4 > .ColumnsGrid > .Column {
    width: calc(100% / 4 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-small-3 > .ColumnsGrid > .Column {
    width: calc(100% / 3 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-small-2 > .ColumnsGrid > .Column {
    width: calc(100% / 2 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-small-1 > .ColumnsGrid > .Column {
    width: 100% !important;
  }

  .ColumnsGrid {
    &.justify-small-left {
      justify-content: flex-start !important;
    }
    &.justify-small-center {
      justify-content: center !important;
    }
    &.justify-small-right {
      justify-content: flex-end !important;
    }
    &.justify-small-space-between {
      justify-content: space-between !important;
    }
  }

  .Column {
    &.align-small-top {
      align-items: flex-start !important;
    }
    &.align-small-center {
      align-items: center !important;
    }
    &.align-small-bottom {
      align-items: flex-end !important;
    }
  }
}

@media only screen and (max-width: 650px) {
  .Columns-extra-small-12 > .ColumnsGrid > .Column {
    width: calc(
      100% / 12 * var(--grid-item-width) - var(--grid-gap)
    ) !important;
  }
  .Columns-extra-extra-small-11 > .ColumnsGrid > .Column {
    width: calc(
      100% / 11 * var(--grid-item-width) - var(--grid-gap)
    ) !important;
  }
  .Columns-extra-small-10 > .ColumnsGrid > .Column {
    width: calc(
      100% / 10 * var(--grid-item-width) - var(--grid-gap)
    ) !important;
  }
  .Columns-extra-small-9 > .ColumnsGrid > .Column {
    width: calc(100% / 9 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-extra-small-8 > .ColumnsGrid > .Column {
    width: calc(100% / 8 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-extra-small-7 > .ColumnsGrid > .Column {
    width: calc(100% / 7 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-extra-small-6 > .ColumnsGrid > .Column {
    width: calc(100% / 6 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-extra-small-5 > .ColumnsGrid > .Column {
    width: calc(100% / 5 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-extra-small-4 > .ColumnsGrid > .Column {
    width: calc(100% / 4 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-extra-small-3 > .ColumnsGrid > .Column {
    width: calc(100% / 3 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-extra-small-2 > .ColumnsGrid > .Column {
    width: calc(100% / 2 * var(--grid-item-width) - var(--grid-gap)) !important;
  }
  .Columns-extra-small-1 > .ColumnsGrid > .Column {
    width: 100% !important;
  }

  .ColumnsGrid {
    &.justify-extra-small-left {
      justify-content: flex-start !important;
    }
    &.justify-extra-small-center {
      justify-content: center !important;
    }
    &.justify-extra-small-right {
      justify-content: flex-end !important;
    }
    &.justify-extra-space-between {
      justify-content: space-between !important;
    }
  }

  .Column {
    &.align-extra-small-top {
      align-items: flex-start !important;
    }
    &.align-extra-small-center {
      align-items: center !important;
    }
    &.align-extra-small-bottom {
      align-items: flex-end !important;
    }
  }
}
