@import "../../../../styles/mixins/responsive.scss";

.BlogAll {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  .toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 24px;
    width: 100%;
    margin: 80px 0 0;
    z-index: 2;
    @include mq(md) {
      display: none;
    }
  }
  .blogs {
    width: 100%;
    margin: 80px 0;
  }

  .toolbarMobile {
    position: fixed;
    z-index: 10000000000;
    inset: 0;
    display: none;
    translate: 100% 0;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 20px 40px 40px;
    gap: 40px;
    overflow: scroll;
    transition: all 0.2s ease-in-out, 0.1s;

    input {
      text-transform: capitalize;
    }

    &[data-open="true"] {
      display: flex;
      translate: 0 0;
    }

    .top {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
      display: flex;
      justify-content: right;
    }

    .content {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
    }

    .bottom {
      width: 100%;
      max-width: 400px;
      margin: 0 auto;
    }
  }

  .filterButton {
    position: fixed;
    bottom: 40px;
    right: 40px;
  }

  .pagination {
    margin: 0 0 80px;
  }
}
