.Steps {
  display: flex;
  gap: 40px;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;

  & > div {
    min-width: calc(50% - 40px);
    width: calc(50% - 40px);
  }

  .StepItems {
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-sizing: border-box;

    .Step {
      border-radius: 16px;
      transition: all 0.2s ease-in-out;
      display: flex;
      justify-content: flex-start;
      box-sizing: border-box;
      width: 100%;
      gap: 24px;
      cursor: pointer;
      position: relative;
      padding-left: 24px;

      h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        margin: 0;
        color: #080808;
        transition: all 0.2s ease-in-out;
      }

      p {
        font-size: 0;
        opacity: 0;
        color: #080808;
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 700;
        line-height: 150%;
        margin: 0;
        transition: all 0.2s ease-in-out;
      }

      .StepContent {
        display: flex;
        flex-direction: column;
      }

      .Progress {
        --progressTime: 7s;
        width: 1px;
        background-color: #d2c9de;
        top: 0;
        bottom: 0;
        position: absolute;
        left: 0;
        transition: all 0.2s ease-in-out;
        .ProgressLevel {
          height: 100%;
          background-color: #080808;
          width: 1px;
        }
      }

      &:hover {
        h4 {
          color: #68f3be;
        }
      }

      &[data-active="true"] {
        box-shadow: 0px 31px 46px rgba(28, 28, 112, 0.05);
        padding: 32px;
        margin: 40px 0;
        background: #ffffff;
        padding-left: 56px;

        .Progress {
          left: 32px;
          top: 32px;
          bottom: 32px;

          .ProgressLevel {
            animation: var(--progressTime) ProgressTime forwards;
          }
        }

        h4 {
          font-size: 32px;
          color: #080808;
        }

        p {
          font-size: 16px;
          opacity: 1;
        }

        .StepContent {
          gap: 16px;
        }
      }
    }
  }

  .StepImage {
    height: auto;
    width: 100%;
    object-fit: contain;
    object-position: 50% 50%;
    border-radius: 16px;
  }
}

.StepsMobile {
  display: none;

  .StepItems {
    display: flex;
    flex-direction: column;
    gap: 16px;
    box-sizing: border-box;

    .Step {
      border-radius: 16px;
      transition: all 0.2s ease-in-out;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      box-sizing: border-box;
      width: 100%;
      gap: 24px;
      cursor: pointer;
      position: relative;
      // padding-left: 24px;

      h4 {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 120%;
        color: #080808;
      }

      p {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 150%;
        color: #000000;

        font-family: "Montserrat";
        margin: 0;
      }

      .StepContent {
        display: flex;
        flex-direction: column;
      }
    }
  }

  .StepImage {
    object-fit: cover;
    object-position: 50% 50%;
    border-radius: 16px;

    width: 80%;
    object-fit: cover;
    object-position: 50% 50%;
    border-radius: 16px;
    aspect-ratio: 2/1;
    max-width: 450px;
  }
}

@keyframes ProgressTime {
  from {
    height: 0;
  }
  to {
    height: 100%;
  }
}

@media only screen and (max-width: 1000px) {
  .Steps {
    display: none;
  }

  .StepsMobile {
    display: flex;

    .StepItems {
      gap: 56px;
    }
  }
}
@media only screen and (max-width: 500px) {
  .StepsMobile {
    .StepImage {
      width: 100%;
    }
  }
}
