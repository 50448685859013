@import "../../../../styles/mixins/responsive.scss";
.dropdown {
  --swiper-arrow-size: 40px;
  --swiper-arrow-gap: 52px;
  --swiper-arrow-gap-mobile: 32px;

  position: relative;
  z-index: 2;

  .dropdownBox {
    z-index: 100;
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    width: fit-content;
    min-width: 100%;
    background-color: var(--white);
    padding: 8px 0;
    border-radius: 8px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
    z-index: 10;
    display: none;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    transition: all 0.2s ease-in-out;
    opacity: 0;
    pointer-events: none;
    transform: translateY(24px);

    &[data-open="true"] {
      display: flex;
      opacity: 1;
      pointer-events: all;
      transform: translateY(0px);
    }

    .dropdownOption {
      padding: 12px 16px;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
      color: #000;
      font-weight: 500;
      line-height: 2;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 12px;
      border: 2px solid transparent;
      box-sizing: border-box;
      width: 100%;
      cursor: pointer;
      min-width: min(fit-content, 100%);
      white-space: nowrap;
      svg {
        color: var(--primary);
        font-size: 20px;
      }

      &[data-active="true"] {
        background-color: #ebecf2;
      }

      &:hover {
        background-color: var(--white-off);
        border: 2px solid black;
      }

      &:focus,
      &:active {
        outline: none;
        border: 2px solid black;
        background-color: var(--white-off);
      }
    }
  }
}
