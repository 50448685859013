.IconItems {
  display: flex;
  flex-direction: column;
  gap: 14px;

  .IconItem {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    align-items: center;
    color: #080808;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    .icon {
      display: flex;
      align-items: center;

      svg {
        width: 24px;
        height: 24px;
        path {
          fill: #080808;
        }
      }
    }
  }

  // a:hover .IconItem {
  //   color: #68f3be !important;
  // }
}
