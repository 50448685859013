.label {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 150%;
  color: #080808;
  margin: 8px 24px;
  width: fit-content;
  max-width: 100%;
  display: inline-block;
}

@media only screen and (max-width: 750px) {
  .image {
    height: auto !important;
  }
}

.MultiImage {
  &[data-quality="low"] img {
    filter: blur(10px);
    clip-path: inset(0);
  }
  &[data-quality="medium"] img {
    filter: blur(5px);
    clip-path: inset(0);
  }
  &[data-quality="high"] img {
    filter: blur(1px);
    clip-path: inset(0);
  }
  &[data-quality="full"] img {
    filter: blur(0);
    // clip-path: inset(0);
    clip-path: unset;
  }
  img {
    // transition: src 0 ease;

    --webkit-backface-visibility: hidden;
    --moz-backface-visibility: hidden;
    --ms-backface-visibility: hidden;
    transition: filter 0.1s linear;
  }
}
