@import "../../../../styles/mixins/responsive.scss";
.Swiper {
  --swiper-arrow-size: 40px;
  --swiper-arrow-gap: 52px;
  --swiper-arrow-gap-mobile: 32px;

  // overflow: visible;
  // overflow-y: visible;
  display: block;
  position: relative;
  &[data-has-arrows="true"] {
    padding: 0 calc(var(--swiper-arrow-gap) + var(--swiper-arrow-size));
  }

  .arrow {
    z-index: 4;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    z-index: 1;
    color: var(--primary);
    border: 1px solid black;

    &:hover {
      background-color: var(--white);
    }
  }

  .arrowPrev {
    left: 0;
    z-index: 4;
  }

  .arrowNext {
    right: 0;
    z-index: 4;
  }

  [class*="swiper"] {
    // overflow: visible;

    [class*="swiper-slide"] {
      padding: 12px;
      transition: all 0.2s ease-in-out;
      &:not([class*="swiper-slide-active"]) {
        opacity: 0;
        pointer-events: none;
      }
    }
  }

  @include mq(md) {
    &[data-has-arrows="true"] {
      padding: 0 0
        calc(var(--swiper-arrow-gap-mobile) + var(--swiper-arrow-size)) 0;

      .arrow {
        top: unset;
        bottom: 0;
        transform: unset;

        &.arrowPrev {
          left: calc(50% - var(--swiper-arrow-size) - 12px);
        }
        &.arrowNext {
          right: calc(50% - var(--swiper-arrow-size) - 12px);
        }
      }
    }
  }
}
