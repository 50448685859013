.SimilarBlogs {
  max-width: 1340px;
  margin: 0 auto;
  .title {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 120%;
    text-align: center;
    color: #080808;
  }

  .blogs {
    padding: 60px;
    gap: 32px;
    display: flex;
    justify-content: center;
    align-items: stretch;
    flex-wrap: wrap;

    & > div {
      min-width: 320px;
      width: calc(33% - 32px);
      display: flex;
    }
  }
}
