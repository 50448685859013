.Project {
  background-color: #1c1c1c;
  width: 100%;
  box-sizing: border-box;
  padding: 50px 0 0;

  .ProjectInner {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    color: white;
    padding: 40px;

    h2 {
      margin: 150px 0;
      max-width: 50%;
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 500;
      font-size: 40px;
      line-height: 140%;
      /* or 56px */

      letter-spacing: -0.04em;
    }

    &.ProjectContent {
      margin: 100px auto 120px;

      p {
        font-family: "Montserrat";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 190%;
      }
    }
  }

  .SlideshowWrapper {
    width: 100%;
  }

  .BackLink {
    display: flex;
    justify-content: flex-start;
    gap: 12px;
    align-items: center;
    color: #c99b5f;
    cursor: pointer;
    font-family: "Montserrat";
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 100%;
    /* identical to box height, or 12px */

    text-align: center;
    letter-spacing: 0.13em;
    text-decoration-line: underline;
    text-transform: uppercase;

    span {
      display: flex;
      svg path {
        fill: #c99b5f;
      }
    }
  }

  .ProjectInfo {
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 100px;

    & > div {
      display: flex;
      flex-direction: column;
      gap: 12px;
      min-width: fit-content;

      h4 {
        font-weight: 600;
        font-size: 16px;
        line-height: 120%;
        letter-spacing: 0.15em;
        text-transform: uppercase;
        color: #c99b5f;
      }

      span {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 190%;
        color: #ffffff;
      }
    }
  }
}

@media only screen and (max-width: 750px) {
  .Project {
    .ProjectInner {
      padding: 40px;
      h2 {
        max-width: 100%;
        margin: 52px 0;
        font-weight: 500;
        font-size: 32px;
        line-height: 140%;
      }

      .ProjectInfo {
        flex-direction: column;
        gap: 32px;
        margin-bottom: 40px;
        & > div h4 {
          font-size: 12px;
          letter-spacing: 0.15em;
        }
      }
    }
    .ProjectContent {
      margin: 32px auto 80px !important;
    }
  }
}
