.accordion {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 32px;
  overflow: hidden;

  .accordionTabs {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 5 !important;

    .accordionTab {
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      font-size: 12px;
      line-height: 100%;
      // letter-spacing: 0.13em;
      font-weight: 600;
      color: #d8dae5;
      box-sizing: border-box;
      padding: 16px;
      position: relative;
      transition: all 0.2s ease-in-out;

      &:after {
        content: "";
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: #d8dae5;
        z-index: 1;
      }

      &[data-active="true"] {
        font-weight: 800;
        color: #4070ff;
      }

      &:hover {
        color: #4070ff;

        &:after {
          background-color: #080808;
        }
      }
    }

    .accordionTabIndicator {
      position: absolute;
      bottom: 0;
      left: 0;
      height: 2px;
      background-color: #1b1b1b;
      z-index: 2;
      transition: all 0.2s ease-in-out;
    }
  }

  .accordionContentWrapper {
    position: relative;
    z-index: 1;
    display: flex;
    width: 100%;
    transition: all 0.2s ease-in-out;
    .accordionContent {
      width: 100%;
      min-width: 100%;
      opacity: 0;
      padding: 16px;
      transition: all 0.4s ease-in-out;

      &[data-active="true"] {
        opacity: 1;
      }

      a {
        text-decoration: underline;
        display: flex;
        gap: 12px;
        align-items: center;
      }
    }
  }

  &[data-style="tabs"] {
    .accordionTabs {
      justify-content: stretch;

      .accordionTab {
        flex: 1;
      }
    }
  }

  &[data-style="sidebar"] {
    background-color: #1b1b1b;
    color: #fff;
    flex-direction: row;
    gap: 60px;

    .accordionTabIndicator {
      display: none;
    }

    .accordionTabs {
      flex-direction: column;
      width: fit-content;
      .accordionTab {
        width: 100%;
        text-align: left;
        padding: 16px 32px 16px 0;
        opacity: 0.5;

        &:before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 0;
          right: 100%;
          height: 2px;
          color: #fff;
          transition: all 0.2s ease-in-out;
          z-index: 2 !important;
        }
        &:after {
          height: 1px;
        }

        &:hover {
          color: #fff;
          opacity: 1;
          &:after {
            background-color: #fff;
          }
        }
        &[data-active="true"] {
          color: #fff;
          opacity: 1;
          &:before {
            right: 0;
          }
        }
      }
    }

    .accordionContentWrapper {
      flex-direction: column;
      width: fit-content;
      flex: 1;

      .accordionContent {
        display: none;
        transition: opacity 0.2s ease-in-out 0.2s, max-height 0.2s ease;

        &[data-active="true"] {
          display: block;
        }

        a {
          font-weight: 600;
          font-size: 12px;
          line-height: 100%;
          text-align: center;
          // letter-spacing: 0.13em;
        }
      }
    }
  }

  &[data-style="normal"] {
    .accordionTabs {
      flex-direction: column;

      .accordionTabNormal {
        border: 1px solid #d8dae5;
        border-left: none;
        border-right: none;
        position: relative;

        &:last-child {
          border-bottom: none;
        }
        .accordionTab {
          text-align: left;
          font-weight: 600;
          font-size: 20px;
          line-height: 140%;
          text-transform: unset;
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: 20px;
          padding: 16px;
          color: #4070ff;
          &:after {
            display: none;
          }
        }
        .tabIndicator {
          scale: 0.8;
        }

        .accordionContentWrapper {
          max-height: 0;
          overflow: hidden;
          transition: all 0.2s ease-in-out;
          &[data-active="true"] {
            max-height: 1000px;
          }

          .accordionContent {
          }
        }
      }
    }
  }

  &[data-stock] {
    &[data-style="tabs"] {
      .tabIndicator {
        display: none;
      }
      .accordionTabs {
        flex-direction: column;

        .accordionTabNormal {
          // border: 1px solid #d8dae5;
          border-left: none;
          border-right: none;
          position: relative;
          border-bottom: none;
          &:first-child {
            border-top: none;
          }

          &:last-child {
            border-bottom: none;
          }
          .accordionTab {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 20px;
            padding: 16px;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 120%;
            /* or 14px */

            text-align: center;
            // letter-spacing: 0.12em;
            text-transform: uppercase;

            color: #d8dae5;
            &:after {
              background-color: #d8dae5;
            }

            &[data-active="true"] {
              color: #1b1b1b;
              &:after {
                height: 2px;
                background-color: #1b1b1b;
              }
            }
          }

          .accordionContentWrapper {
            margin: 16px 0;
            max-height: 0;
            overflow: hidden;
            transition: all 0.2s ease-in-out;
            &[data-active="true"] {
              max-height: 1000px;
            }

            .accordionContent {
            }
          }
        }
      }
    }
    &[data-style="sidebar"] {
      .tabIndicator {
        display: none;
      }
      .accordionTabs {
        flex-direction: column;

        .accordionTabNormal {
          // border: 1px solid #d8dae5;
          border-left: none;
          border-right: none;
          position: relative;
          border-bottom: none;
          text-align: left;
          width: 100%;
          &:first-child {
            border-top: none;
          }

          &:last-child {
            border-bottom: none;
          }
          .accordionTab {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 20px;
            padding: 16px 0;
            font-family: "Montserrat";
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 120%;
            text-align: left;
            /* or 14px */
            width: 100%;

            // letter-spacing: 0.12em;
            text-transform: uppercase;

            color: #8d8d8d;
            &:after {
              background-color: #8d8d8d;
              width: 100%;
            }

            &[data-active="true"] {
              color: #fff;
              &:after {
                height: 2px;
                background-color: #fff;
              }
            }
          }

          .accordionContentWrapper {
            margin: 16px 0;
            max-height: 0;
            overflow: hidden;
            transition: all 0.2s ease-in-out;
            &[data-active="true"] {
              max-height: 1000px;
            }

            .accordionContent {
            }
          }
        }
      }
    }
  }
}

.icon {
  color: black;
}

@media only screen and (max-width: 1000px) {
  .accordion[data-style="sidebar"] {
    .accordionContentWrapper {
      .accordionContent {
        display: block;
      }
    }
  }
}
