.FormBlock {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  overflow: visible;

  .FormForm {
    color: #000;
    box-sizing: border-box;

    form {
      display: flex;
      justify-content: flex-start;
      flex-wrap: wrap;
      gap: 64px;
      margin: 32px 0 0;

      .lnf-input {
        width: 100%;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .FormBlock {
    flex-wrap: wrap;
  }
  .FormForm,
  .FormInfo {
    // min-width: 100%;
  }
}

@media only screen and (max-width: 680px) {
  .FormBlock {
    overflow: visible;
  }

  .FormForm form .FormField {
    width: 100% !important;
  }
}
