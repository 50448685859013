.ImageSlideShow {
  display: flex;
  gap: 32px;
  overflow: hidden;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  flex-direction: column;

  .actions {
    display: flex;
    justify-content: flex-end;
    gap: 26px;
    align-items: center;
    width: 80%;

    .count {
      color: #fff;
      text-align: center;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 190%;
    }

    .left {
    }

    .right {
    }

    .left,
    .right {
      transition: all 0.2s ease-in-out;
      cursor: pointer;

      width: 38px;
      height: 38px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        display: flex;
      }
      svg {
        // scale: 1.8;

        path {
          fill: #c99b5f;
        }
      }
      &[data-disabled="true"] {
        pointer-events: none;

        svg {
          path {
            fill: grey;
          }
        }
      }
    }
  }

  .slides {
    --currentSide: 0;
    display: flex;
    width: 80%;
    transition: all 0.2s ease-in-out;
    translate: calc(var(--currentSide) * -100%) 0;

    .slideWrapper {
      width: 100%;
      min-width: 100%;
      transition: all 0.4s ease-in-out;
      opacity: 1;

      .slide {
        border-radius: 16px;
        box-sizing: border-box;
        height: 100%;

        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 20px;
        transition: all 0.2s ease-in-out;

        & > div {
          display: flex;
          flex-direction: column;
          height: fit-content;
        }

        img {
          width: 100%;
          height: 530px;
        }
      }

      opacity: 0;
      &[data-active="true"] {
        opacity: 1;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .ImageSlideShow {
    .slides {
      width: 100%;

      .slideWrapper {
        .slide {
          & > div {
            height: fit-content !important;
          }
          img {
            height: 60vw !important;
          }
        }
      }
    }

    .actions {
      justify-content: center;
      width: 100%;
      .left {
        left: 32px;
        // translate: 0 -50%;
      }

      .right {
        right: 32px;
        // translate: 0 -50%;
      }
    }
  }
}
