.FooterContent {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  .left {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    .logo img {
      width: 152px;
      height: auto;
    }
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-start;
    gap: 32px;

    .address {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 150%;
      text-align: right;
      color: #ffffff;
    }

    .copy {
      font-family: "Montserrat";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 150%;
      text-align: right;
      color: #ffffff;
    }

    .links {
      display: flex;
      justify-content: flex-end;
      gap: 32px;
      flex-wrap: wrap;
      align-items: center;

      a {
        font-family: "Poppins";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 120%;
        color: #ffffff;

        &:hover {
          color: #68f3be !important;
        }
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .FooterContent {
    .left {
      width: 100%;
      margin-bottom: 58px;
    }

    .right {
      width: 100%;
      justify-content: flex-start;
      align-items: flex-start;
      text-align: left;

      .FooterContent,
      .address,
      .copy {
        text-align: left;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .address {
        margin-bottom: 32px;
      }

      .links {
        flex-direction: column;
        align-items: flex-start;
      }
    }
  }
}
