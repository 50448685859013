.Graphic {
  position: absolute;
  --insetM: unset;
  --insetS: var(--insetM);
  --insetXS: var(--insetS);
  --insetL: var(--insetM);
  --insetXL: var(--insetL);

  --translateM: unset;
  --translateS: var(--translateM);
  --translateXS: var(--translateS);
  --translateL: var(--translateM);
  --translateXL: var(--translateL);

  --rotateM: unset;
  --rotateS: var(--rotateM);
  --rotateXS: var(--rotateS);
  --rotateL: var(--rotateM);
  --rotateXL: var(--rotateL);

  --zIndexM: unset;
  --zIndexS: var(--zIndexM);
  --zIndexXS: var(--zIndexS);
  --zIndexL: var(--zIndexM);
  --zIndexXL: var(--zIndexL);

  --heightM: unset;
  --heightS: var(--heightM);
  --heightXS: var(--heightS);
  --heightL: var(--heightM);
  --heightXL: var(--heightL);

  --widthM: unset;
  --widthS: var(--widthM);
  --widthXS: var(--widthS);
  --widthL: var(--widthM);
  --widthXL: var(--widthL);

  // transition: all 0.2s ease-in-out;
  .image {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }
}

@media only screen and (min-width: 1000px) {
  .Graphic {
    inset: var(--insetM);
    translate: var(--translateM);
    rotate: var(--rotateM);
    z-index: var(--zIndexM);
    width: var(--widthM);
    height: var(--heightM);
  }
}

@media only screen and (min-width: 1400px) {
  .Graphic {
    inset: var(--insetL);
    translate: var(--translateL);
    rotate: var(--rotateL);
    z-index: var(--zIndexL);
    width: var(--widthL);
    height: var(--heightL);
  }
}

@media only screen and (min-width: 1800px) {
  .Graphic {
    inset: var(--insetXL);
    translate: var(--translateXL);
    rotate: var(--rotateXL);
    z-index: var(--zIndexXL);
    width: var(--widthXL);
    height: var(--heightXL);
  }
}

@media only screen and (max-width: 1000px) {
  .Graphic {
    inset: var(--insetS);
    translate: var(--translateS);
    rotate: var(--rotateS);
    z-index: var(--zIndexS);
    width: var(--widthS);
    height: var(--heightS);
  }
}

@media only screen and (max-width: 450px) {
  .Graphic {
    inset: var(--insetXS);
    translate: var(--translateXS);
    rotate: var(--rotateXS);
    z-index: var(--zIndexXS);
    width: var(--widthXS);
    height: var(--heightXS);
  }
}
