@import "../../styles/responsive";

.headerWrapper {
  transition: height 0.2s ease-in-out;

  .header {
    background-color: var(--header-static-bg);
    position: absolute;
    top: 0;
    width: 100vw;
    z-index: 1000;
    padding: var(--header-static-pd);
    background-size: cover;
    background-position: 50% 50%;
    transition: all 0.2s ease-in-out;

    > .headerInner {
      // width constraint for header content
      display: flex;
      flex-direction: row;
      max-width: var(--header-static-container);
      margin: 0 auto;
      position: relative;
      gap: var(--navigation-items-gap);
      align-items: center;
      justify-content: space-between;

      a.logoLink {
        display: flex;
        align-items: center;
        .logo {
          height: var(--header-static-logo-h);
          width: auto;

          display: block;
          position: relative;
          z-index: 20;
        }
      }

      .content {
        display: flex;
        gap: var(--navigation-items-gap);
        flex: 1;
        justify-content: var(--navigation-align);

        .links {
          display: flex;
          justify-content: var(--navigation-align);
          gap: var(--navigation-items-gap);
          align-items: center;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          text-align: left;

          a {
            color: var(--navigation-item-color);

            position: relative;
            padding-bottom: 5px;
            font-weight: 700;
            font-size: 14px;
            line-height: 200%;
            cursor: pointer;

            &:after {
              content: "";
              height: 6px;
              background-color: var(--primary);
              width: 0;
              position: absolute;
              top: calc(100% + 30px);
              left: 50%;
              translate: -50%;
              border-radius: 100px;
              transition: all 0.2s ease-in-out;
            }

            &:hover {
              color: var(--navigation-item-color-hover) !important;
            }
            &:active,
            :focus {
              border: 2px solid var(--primary-primary-blue, #4070ff);
            }

            &:hover,
            &[data-active="true"] {
              &:after {
                width: 100%;
              }
            }
          }
        }
      }
    }

    .mobileNavButton {
      display: none;
      position: fixed;
      top: 20px;
      right: 20px;
      z-index: 10000;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      color: var(--primary);
      span {
        display: flex;
      }
    }

    .mobileNav {
      font-family: var(--font);
      background-color: var(--navigation-mobile-bg);
      padding: var(--navigation-mobile-pd);
      color: var(--navigation-mobile-text);
      gap: 60px;

      display: none;
      position: fixed;
      inset: 0 0 0 0;
      translate: 100% 0;
      transition: all 0.2s ease-in-out;
      flex-direction: column;
      z-index: -999;
      justify-content: space-between;
      align-items: center;
      min-height: 100vh;
      overflow: scroll;

      &[data-open="true"] {
        translate: 0 0;
        z-index: 999;
      }

      .mobileNavTop {
        width: 100%;
        max-width: 400px;
        display: flex;
        align-items: center;
      }
      .mobileNavCenter {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex: 1;
      }
      .mobileNavBottom {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .mobileLogo {
        img {
          max-width: 120px;
        }
      }

      .mobileLinks {
        max-width: 400px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: var(--navigation-mobile-items-gap);
        flex-wrap: wrap;
        justify-content: left;
        align-items: left;
        margin-top: 32px;
        margin-bottom: 32px;
        text-align: left;
        flex: 1;
        min-height: fit-content;

        a {
          font-weight: 700;
          font-size: 14px;
          line-height: 2;
          color: var(--navigation-mobile-text);
          justify-content: space-between;
          align-items: center;
          display: flex;

          svg {
            font-size: 24px;
            color: var(--primary);
          }

          &:hover {
            color: var(--navigation-mobile-text);
          }
        }
      }

      .mobileContent {
        width: fit-content;
        text-align: left;
        color: inherit;
        max-width: 400px;
        width: 100%;

        *,
        p {
          margin-bottom: 0 !important;
          color: inherit;
          font-weight: 400;
          font-size: 12px;
          line-height: 190%;
        }
      }
    }

    &[data-sticky="true"] {
      padding: var(--header-sticky-pd);
      background-color: var(--header-sticky-bg) !important;
      position: fixed;

      .mobileNavButton {
        top: 16px;
        right: 20px;
      }

      & > .headerInner {
        max-width: var(--header-sticky-container);
      }
      a.logoLink {
        .logo {
          height: var(--header-sticky-logo-h);
        }
      }

      .content {
        .links {
          a {
            &:after {
              content: "";

              top: calc(100% + 19px);
            }
          }
        }
      }

      .mobileNavButton {
        // top: 6px;
      }
    }
  }
}

@media only screen and (max-width: 1000px) {
  .headerWrapper {
    &[data-sticky="true"] {
      margin-bottom: 24px;
    }
    .header {
      padding: 20px 20px;

      .logo {
        width: 100px;
        height: auto;
      }

      & > .headerInner {
        .content,
        .right {
          display: none;
        }
      }
    }

    &[data-ready="true"] {
      .mobileNav,
      .mobileNavButton {
        display: flex;
      }
    }
  }
}
@media only screen and (max-width: 750px) {
}

@media only screen and (max-width: 450px) {
  .headerWrapper {
    .header {
      .headerInner {
        & > a {
          img {
            height: 32px;
            width: auto;
          }
        }
      }
    }
  }
}
