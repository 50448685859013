.ItemCard {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .image {
    object-position: 50% 50%;
  }

  h4 {
    margin-top: 16px;
    color: var(--color-primary) !important;
  }

  p {
    font-size: var(--content-font-size) !important;
    font-weight: 400;
    font-family: var(--font-family-secondary);
    margin: 0;
  }

  &[data-align="center"] {
    align-items: center;
    text-align: center;
  }

  &[data-align="right"] {
    align-items: flex-end;
    text-align: right;
  }
}
